import Component from "../../../../shared/js/common/component";

export default class MobileNavigation extends Component {
    static readonly componentName: string = 'top-navigation';

    body: HTMLElement;
    button: Element;
    closeHelper: NodeListOf<Element>;
    mql: MediaQueryList;
    bodyScrollTop: number;

    init() {
        this.body = document.body;
        this.button = document.querySelector('.mobile-menu-button');
        this.closeHelper = document.querySelectorAll('.mobile-navigation__close-helper, .mobile-menu-button--close');
        this.mql = window.matchMedia('(min-width: 992px)');
        this.bodyScrollTop = 0;

        this.registerEvents();
    }

    registerEvents() {
        this.mql.addEventListener('DOMContentLoaded', () => {
            if(this.mql.matches) {
                this.closeNavigation();
            }
        })

        this.button.addEventListener('click', () => {
            this.openNavigation();
        })

        /*
        this.closeHelper.addEventListener('click', () => {
            this.closeNavigation();
        })

         */

        this.closeHelper.forEach((item) => {
            item.addEventListener('click', () => {
                this.closeNavigation();
            })
        })
    }

    openNavigation() {
        this.body.classList.add('mobile-menu-open');
    }

    closeNavigation() {
        this.body.classList.remove('mobile-menu-open');
    }

}