import Component from "../js/common/component";

export default class TourDetail extends Component {
    static readonly componentName: string = "tour-details";

    init() {
        if (window.matchMedia('(min-width: 768px)').matches) {
            window.onload = () => {
                this.adjustElementHeights('.tour-details__edition.first');
                this.adjustElementHeights('.tour-details__edition.second');
                this.adjustElementHeights('.tour-details__edition.third');
            };
        }

        this.initCollapseIcon();
    }

    initCollapseIcon() {
        document.addEventListener('DOMContentLoaded', () => {
            const collapseIconNotes = document.getElementById('collapseIconNotes');
            const collapseIconDescription = document.getElementById('collapseIconDescription');
            const tourDescriptionTitle = document.querySelector('.tour-details__tour-description-title') as HTMLElement;

            if (!collapseIconNotes) return;

            function toggleIcon(collapseIcon: HTMLElement) {
                const icon = collapseIcon.querySelector('i');

                if (!icon) return;

                if (collapseIcon.getAttribute('aria-expanded') === 'true') {
                    icon.classList.remove('fa-chevron-circle-down');
                    icon.classList.add('fa-chevron-circle-up');
                    if (tourDescriptionTitle) {
                        tourDescriptionTitle.style.display = 'none';
                    }
                } else {
                    icon.classList.remove('fa-chevron-circle-up');
                    icon.classList.add('fa-chevron-circle-down');
                    if (tourDescriptionTitle) {
                        tourDescriptionTitle.style.display = 'block';
                    }
                }
            }

            if (collapseIconNotes) {
                collapseIconNotes.addEventListener('click', () => {
                    toggleIcon(collapseIconNotes);
                });
                toggleIcon(collapseIconNotes);
            }

            if (collapseIconDescription) {
                collapseIconDescription.addEventListener('click', () => {
                    toggleIcon(collapseIconDescription);
                });
                toggleIcon(collapseIconDescription);
            }
        });
    }

    adjustElementHeights(selector: string) {
        const elements = document.querySelectorAll(selector);
        let maxHeight = 0;

        elements.forEach((element: HTMLElement) => {
            maxHeight = Math.max(maxHeight, element.clientHeight);
        });

        elements.forEach((element: HTMLElement) => {
            element.style.height = maxHeight + 'px';
        });
    }
}