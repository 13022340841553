/**
 * Import libraries -> use global typescript file
 */

/**
 * Import modules
 */
import CommonApplication from './../../../shared/js/application';

// import App from './Component/Content/App';
import MobileNavigation from "./navigation/mobile-navigation";
import TourDetail from './../../../shared/js/tour-detail';

import Teasers from "./bricks/teasers";

/**
 * Application class
 */
class Application extends CommonApplication {
    run() {
        super.run();

        MobileNavigation.initialize();
        TourDetail.initialize();
        Teasers.initialize();
        // App.initialize();
    }

}

export default Application;